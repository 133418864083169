import React from "react"
import Layout from "../containers/Layout";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import SEO from "../components/genericComponents/SEO";

const LegalPage = props => {
  const { t } = useTranslation()

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
      <SEO
        title={t('seo_aviso_legal_title')}
        desc={t('seo_aviso_legal_description')}
        pathname={"/aviso_legal/"}
        article={false}
      />
      <section className="terms-content container-generic" data-scroll-section>
        <h1>{t("terms_title")}</h1>
        <p>{t('terms_content0')}</p>
        <h3>{t('terms_section_title1')}</h3>
        <p>{t('terms_section_content1')}</p>
        <h3>{t('terms_section_title2')}</h3>
        <p>{t('terms_section_content2')}</p>
        <h3>{t('terms_section_title3')}</h3>
        <p>{t('terms_section_content3')}</p>
        <h3>{t('terms_section_title4')}</h3>
        <p>{t('terms_section_content4')}</p>
        <h3>{t('terms_section_title5')}</h3>
        <p>{t('terms_section_content5')}</p>
        <h3>{t('terms_section_title6')}</h3>
        <p>{t('terms_section_content6')}</p>
        <h3>{t('terms_section_title7')}</h3>
        <p>{t('terms_section_content7')}</p>
        <h3>{t('terms_section_title8')}</h3>
        <p>{t('terms_section_content8')}</p>
        <h2>{t('terms_subtitle1')}</h2>
        <h3>{t('terms_section_title9')}</h3>
        <p>{t('terms_section_content9')}</p>
        <h3>{t('terms_section_title10')}</h3>
        <p>{t('terms_section_content10')}</p>
        <h3>{t('terms_section_title11')}</h3>
        <p>{t('terms_section_content11')}</p>
        <h3>{t('terms_section_title12')}</h3>
        <p>{t('terms_section_content12')}</p>
        <h3>{t('terms_section_title13')}</h3>
        <p>{t('terms_section_content13')}</p>
        <h3>{t('terms_section_title14')}</h3>
        <p>{t('terms_section_content14')}</p>
        <h3>{t('terms_section_title15')}</h3>
        <p>{t('terms_section_content15')}</p>
      </section>
    </Layout>
  )
}

export default LegalPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
